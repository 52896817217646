import React, { FC, useEffect } from 'react'
import {createSearchParams, useNavigate, useSearchParams} from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import ElixirIcon24 from 'components/shared/icons/24x24/ElixirIcon24'

import schema from './DomainsPurchaseForm.schemas'

import {
  DomainsStorageTableHeadIds,
  FetchDomainsStorageList,
  PurchaseDomains,
  PurchaseDomainsValues
} from 'types/domains.types'
import {
  PATH_DOMAINS_STORAGE,
  PATH_DOMAINS_STORAGE_DEFAULT
} from 'constants/routes.constants'
import {Credential} from 'types/user.types'
import {RegistrarItem} from 'types/materials.types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {SortMethod} from "types/shared.types"
import {DEFAULT_PAGINATION_PER_PAGE} from "constants/shared.contants"
import {
  DOMAINS_PURACHASE_VALUES_DEFAULTS
} from "constants/domains.constants"
interface DomainsPurcasheFormParams {
  domains: number[]
  fetchDomainsStorageList: FetchDomainsStorageList
  registrarsList: Credential[],
  purchaseDomains: PurchaseDomains
}

const DomainsPurchaseForm: FC<DomainsPurcasheFormParams> = ({
  domains,
  fetchDomainsStorageList,
  registrarsList,
  purchaseDomains
}) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as DomainsStorageTableHeadIds) || DomainsStorageTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || DEFAULT_PAGINATION_PER_PAGE

  const isWidth300 = useMediaQuery('(max-width:300px)')

  const { registrarId } = DOMAINS_PURACHASE_VALUES_DEFAULTS

  useEffect(() => {
    if (!domains.length) {
      navigate({
        pathname: PATH_DOMAINS_STORAGE,
        search: searchParams.toString()
      })
    }
  }, [])


  const domainsPurcasheValues: PurchaseDomainsValues = {
    domains: domains,
    credential_registrar_id: null,
  }

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: domainsPurcasheValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })


  useEffect(() => {
    if (registrarsList.length === 1) {
      setValue('credential_registrar_id', String(registrarsList[0].id))
      return
    }

    if (registrarsList.length) {
      setValue('credential_registrar_id', String(registrarsList.find(reg => reg.name === registrarId)?.id) || '')
    }
  }, [registrarsList])

  const handlePurchaseDomains = async (values: PurchaseDomainsValues) => {
    try {
      await purchaseDomains(values)

      handleFetchDomainsStorageList()
      navigate(PATH_DOMAINS_STORAGE_DEFAULT)
    } catch (error) {
      console.log(error)
    }

  }

  const handleFetchDomainsStorageList = () => {
    fetchDomainsStorageList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_DOMAINS_STORAGE,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  return (
      <form onSubmit={handleSubmit(handlePurchaseDomains)}>

        <FieldAutocomplete
            sx={{ mb: 2 }}
            label="FORM.FIELD.TITLE.DOMAINS_PURCHASE_TITLE"
            name="credential_registrar_id"
            control={control}
            optionsList={registrarsList}
            getValue={value => (value ? registrarsList.find(option => Number(option.id) === Number(value)) : '')}
            getNewValue={(newValue: RegistrarItem| null) => newValue?.id || null}
            getOptionLabel={option => (option ? intl.formatMessage({ id: option?.name }) : '')}
            renderOption={(props, { name }) => (
                <MenuItem {...props} key={name}>
                  {name}
                </MenuItem>
            )}
        />
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
              type="submit"
              variant="contained"
              size={isWidth300 ? 'small' : 'large'}
              fullWidth
          >
            {intl.formatMessage({ id: 'DOMAINS_PURCHASE_PAGE.BUTTON.SUBMIT.PURCHASE' })}
          </Button>
        </Box>
      </form>
  )
}

export default DomainsPurchaseForm
