import { object, boolean, number } from 'yup'
import { requiredStringSchema } from 'utils/schemas.utils'

export default object().shape({
  domains: requiredStringSchema,
  validation: boolean().default(true),
  bought: boolean().default(false),

  credentialParserId: number()
      .transform((value, originalValue) => (originalValue === '' ? null : value)) // Перетворює '' → null
      .nullable()
      .when('validation', {
        is: true,
        then: number().required('FORM.FIELD.VALIDATION.ERROR.REQUIRED'),
        otherwise: number().nullable()
      }),

  credentialRegistrarId: number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .when('bought', {
        is: true,
        then: number().required('FORM.FIELD.VALIDATION.ERROR.REQUIRED'),
        otherwise: number().strip() // Видаляє поле, якщо bought === false
      })
})
