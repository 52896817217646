import { StatisticsState, StatisticsActionType, SetStatisticsAction, SetDomainStatisticsAction } from 'types/statistics.types'
import { camelCaseArray, camelCaseKeys } from 'utils/shared.utils'
import { normalizePartnersStatisticsList } from './statistics.normalizers'

type StatisticsAction = SetStatisticsAction | SetDomainStatisticsAction

const initialState: StatisticsState = {
  commonStatistics: null,
  partnerStatisticsList: [],
  farmsStatisticsList: [],
  domainStatistics: []
}

const statistics = (state = initialState, { type, payload }: StatisticsAction) => {
  switch (type) {
    case StatisticsActionType.SET_STATISTICS:
      const { common, partner, farm } = payload
      return {
        ...state,
        commonStatistics: camelCaseKeys(common),
        partnerStatisticsList: normalizePartnersStatisticsList(common, partner),
        farmsStatisticsList: camelCaseArray(farm)
      }
      case StatisticsActionType.SET_DOMAIN_STATISTICS:
      return {
        ...state,
        domainStatistics: camelCaseKeys(payload) // Збереження статистики доменів
      }
    default:
      return state
  }
}

export default statistics
