import { RootState } from 'types/system.types'

export const getStatistics = (state: RootState) => state.statistics

export const getCommonStatistics = (state: RootState) => getStatistics(state).commonStatistics

export const getPartnerStatisticsList = (state: RootState) => getStatistics(state).partnerStatisticsList

export const getFarmsStatisticsList = (state: RootState) => getStatistics(state).farmsStatisticsList

export const  getDomainStatistics = (state: RootState) => getStatistics(state).domainStatistics