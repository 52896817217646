import { connect } from 'react-redux'
import {RootState} from "types/system.types"
import {deleteDomains, fetchDomainsStorageList, getDomainsStorageList, purchaseDomains} from 'modules/domains'
import {getDomainStatistics} from 'modules/statistics'
import DomainsStorage from './DomainsStorage'


const mapStateToProps = (state: RootState) => ({
  domainsList: getDomainsStorageList(state),
  domainStatistics: getDomainStatistics(state)
})

const mapDispatchToProps = {
  deleteDomains,
  purchaseDomains,
  fetchDomainsStorageList,
  getDomainsStorageList
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsStorage)
