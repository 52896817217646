import {FetchDomainStatistics, FetchStatistics, StatisticsActionType, StatisticsPayload} from 'types/statistics.types'
import { AxiosDispatch } from 'types/system.types'
import { logError } from 'utils/system.utils'

export const fetchStatistics: FetchStatistics = (dateStart, dateFinal) => {
  return {
    type: StatisticsActionType.FETCH_STATISTICS,
    payload: {
      request: {
        url: `/materials/statistics/common?date_start=${dateStart}&date_final=${dateFinal}`
      },
      options: {
        onSuccess({ dispatch, response }: { response: StatisticsPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: StatisticsActionType.SET_STATISTICS,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const fetchDomainStatistics: FetchDomainStatistics = () => {
  return {
    type: StatisticsActionType.FETCH_DOMAIN_STATISTICS,
    payload: {
      request: {
        url: '/materials/domainsSandbox/statistics',
      },
      options: {
        onSuccess({ dispatch, response }: { response: StatisticsPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: StatisticsActionType.SET_DOMAIN_STATISTICS,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}
