import React, { FC, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import useMediaQuery from '@mui/material/useMediaQuery'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from 'components/shared/Alert'
import FieldNumber from 'components/shared/form/FieldNumber'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import FieldDateTimePicker from 'components/shared/form/FieldDateTimePicker'
import ElixirIcon24 from 'components/shared/icons/24x24/ElixirIcon24'
import CloseIcon12 from 'components/shared/icons/12x12/CloseIcon12'
import {
  FetchServersList,
  ServersList,
  FetchFarmersList,
  FarmersList,
  FarmersItem,
  ServersItem
} from 'types/materials.types'
import { KeysGroupsItem, KeysGroupsList } from 'types/keys.types'
import { FetchCampaignsList, CampaignsList, CampaignsItem } from 'types/campaigns.types'
import {
  DomainsCreateValues,
  CreateDomains,
  DomainsTemplateItem,
  DomainsTypeOption,
  AnchorsTypeOption
} from 'types/domains.types'
import {
  PATH_DOMAINS_STORAGE,
  PATH_DOMAINS_ACTIVE_DEFAULT,
  PATH_CAMPAIGNS,
  PATH_KEYS_IMPORTS_DEFAULT
} from 'constants/routes.constants'
import {
  DOMAINS_TYPES_OPTIONS,
  DOMAINS_CREATE_VALUES_DEFAULTS,
  DOMAINS_TEMPLATES_LIST,
  ANCHORS_TYPES_OPTIONS
} from 'constants/domains.constants'
import schema from './DomainsCreateForm.schemas'

interface DomainsCreateFormParams {
  domains: number[]
  fetchCampaignsList: FetchCampaignsList
  keysGroupsList: KeysGroupsList
  campaignsList: CampaignsList
  fetchServersList: FetchServersList
  serversList: ServersList
  fetchFarmersList: FetchFarmersList
  farmersList: FarmersList
  createDomains: CreateDomains
}

const DomainsCreateForm: FC<DomainsCreateFormParams> = ({
  domains,
  fetchCampaignsList,
  keysGroupsList,
  campaignsList,
  fetchServersList,
  serversList,
  fetchFarmersList,
  farmersList,
  createDomains
}) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isWidth300 = useMediaQuery('(max-width:300px)')
  const isWidth345 = useMediaQuery('(max-width:345px)')

  const { linksGenerationType, anchorGenerationType, linksLimit, template, farmerId, campaignId } =
    DOMAINS_CREATE_VALUES_DEFAULTS

  useEffect(() => {
    if (!domains.length) {
      navigate({
        pathname: PATH_DOMAINS_STORAGE,
        search: searchParams.toString()
      })
    }
  }, [])

  useEffect(() => {
    fetchServersList()
    fetchCampaignsList()
    fetchFarmersList()
  }, [])

  const domainsCreateValues: DomainsCreateValues = {
    domains: domains,
    linksGenerationType,
    anchorGenerationType,
    groupId: [],
    farmerId: '',
    datetimeStart: null,
    campaignId: '',
    linksLimit,
    template
  }

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: domainsCreateValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (farmersList.length === 1) {
      setValue('farmerId', String(farmersList[0].id))
      return
    }

    if (farmersList.length) {
      setValue('farmerId', String(farmersList.find(farmer => farmer.title === farmerId)?.id) || '')
    }
  }, [farmersList])

  useEffect(() => {
    if (campaignsList.length === 1) {
      setValue('campaignId', String(campaignsList[0].id))
      return
    }

    if (campaignsList.length) {
      setValue('campaignId', String(campaignsList.find(campaign => campaign.title === campaignId)?.id) || '')
    }
  }, [campaignsList])

  const handleCreateDomains = (values: DomainsCreateValues) => {
    try {
      createDomains(values)

      navigate(PATH_DOMAINS_ACTIVE_DEFAULT)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleCreateDomains)}>
      <FieldDateTimePicker
          sx={{ mb: 2 }}
          name="datetimeStart"
          control={control}
          disablePast
          label="FORM.FIELD.TITLE.DOMAINS_TIME"
      />
      <FieldAutocomplete
          sx={{ mb: keysGroupsList.length ? 2 : 0 }}
          multiple
          label="FORM.FIELD.TITLE.DOMAINS_KEYS_GROUPS"
          name="groupId"
          control={control}
          limitTags={2}
          optionsList={keysGroupsList}
          readOnly={!keysGroupsList.length}
          getValue={value => (value ? keysGroupsList.filter(option => value.includes(option.id)) : [])}
          getNewValue={(newValue: KeysGroupsItem[]) => newValue?.map(group => group.id) || []}
          getOptionLabel={(option: KeysGroupsItem) => (option ? option?.title : '')}
          renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                  <Chip
                      key={option?.id}
                      label={option?.title.split(' (')[0]}
                      {...getTagProps({ index })}
                      deleteIcon={<CloseIcon12 />}
                  />
              ))
          }
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {title}
              </MenuItem>
          )}
      />
      {!keysGroupsList.length && (
          <Alert severity="warning" sx={{ width: '100%', mb: 4 }}>
            <AlertTitle
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: 'DOMAINS_CREATE_FORM.ALERT.KEYS_GROUPS_LIST_EMPTY' })
                }}
            />
            {!isWidth345 && (
                <Button
                    sx={{ mt: 1.5, mb: 0.5 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => navigate(PATH_KEYS_IMPORTS_DEFAULT)}
                >
                  {intl.formatMessage({ id: 'DOMAINS_CREATE_PAGE.BUTTON.IMPORT_KEYS' })}
                </Button>
            )}
          </Alert>
      )}
      <FieldAutocomplete
        sx={{ mb: 2 }}
        label="FORM.FIELD.TITLE.DOMAINS_TYPE"
        name="linksGenerationType"
        control={control}
        optionsList={DOMAINS_TYPES_OPTIONS}
        getValue={value => (value ? DOMAINS_TYPES_OPTIONS.find(option => option.id === value) : '')}
        getNewValue={(newValue: DomainsTypeOption | null) => newValue?.id || null}
        getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
        renderOption={(props, { title, text }) => (
          <MenuItem {...props} key={title}>
            <Box width="100%">
              <Typography variant="XSRegular">{intl.formatMessage({ id: title })}</Typography>
              <Typography variant="XXSRegular" noWrap color="textSecondary">
                {text}
              </Typography>
            </Box>
          </MenuItem>
        )}
      />
      <FieldAutocomplete
        sx={{ mb: 2 }}
        label="FORM.FIELD.TITLE.ANCHOR_TYPE"
        name="anchorGenerationType"
        control={control}
        optionsList={ANCHORS_TYPES_OPTIONS}
        getValue={value => (value ? ANCHORS_TYPES_OPTIONS.find(option => option.id === value) : '')}
        getNewValue={(newValue: AnchorsTypeOption | null) => newValue?.id || null}
        getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
        renderOption={(props, { title }) => (
          <MenuItem {...props} key={title}>
            {title}
          </MenuItem>
        )}
      />

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.DOMAINS_TEMPLATE"
          name="template"
          control={control}
          optionsList={DOMAINS_TEMPLATES_LIST}
          getValue={value =>
              value ? DOMAINS_TEMPLATES_LIST.find(template => String(template.id) === String(value)) : null
          }
          getNewValue={(newValue: DomainsTemplateItem | null) => newValue?.id || ''}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { id, title }) => (
              <MenuItem {...props} key={id}>
                {intl.formatMessage({ id: title })}
              </MenuItem>
          )}
      />

      <FieldNumber
          sx={{ mb: 2 }}
          control={control}
          name="linksLimit"
          label="FORM.FIELD.TITLE.DOMAINS_LINKS_AMOUNT"
          numberProps={{ decimalScale: 0 }}
      />

      <FieldAutocomplete
        sx={{ mb: campaignsList.length ? 2 : 0 }}
        label="FORM.FIELD.TITLE.DOMAINS_CAMPAIGN"
        name="campaignId"
        control={control}
        optionsList={campaignsList}
        readOnly={!campaignsList.length}
        getValue={value => (value ? campaignsList.find(campaign => String(campaign.id) === String(value)) : null)}
        getNewValue={(newValue: CampaignsItem | null) => newValue?.id || ''}
        getOptionLabel={option => (option ? option?.title : '')}
        renderOption={(props, { id, title }) => (
          <MenuItem {...props} key={id}>
            {title}
          </MenuItem>
        )}
      />
      {!campaignsList.length && (
        <Alert severity="warning" sx={{ width: '100%', mb: 4 }}>
          <AlertTitle
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'DOMAINS_CREATE_FORM.ALERT.CAMPAIGNS_LIST_EMPTY' })
            }}
          />
          {!isWidth345 && (
            <Button
              sx={{ mt: 1.5, mb: 0.5 }}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => navigate(PATH_CAMPAIGNS)}
            >
              {intl.formatMessage({ id: 'DOMAINS_CREATE_PAGE.BUTTON.CREATE_CAMPAIGN' })}
            </Button>
          )}
        </Alert>
      )}

      <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.DOMAINS_SPAM"
          name="farmerId"
          control={control}
          optionsList={farmersList}
          getValue={value => (value ? farmersList.find(option => Number(option.id) === Number(value)) : '')}
          getNewValue={(newValue: FarmersItem | null) => newValue?.id || null}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
          renderOption={(props, { title }) => (
              <MenuItem {...props} key={title}>
                {title}
              </MenuItem>
          )}
      />
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          type="submit"
          variant="contained"
          size={isWidth300 ? 'small' : 'large'}
          fullWidth
          startIcon={<ElixirIcon24 />}
        >
          {intl.formatMessage({ id: 'DOMAINS_CREATE_PAGE.BUTTON.SUBMIT.CREATE' })}
        </Button>
      </Box>
    </form>
  )
}

export default DomainsCreateForm
