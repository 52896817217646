import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SelectIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 22" fontSize="icon24" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M3 0.999634C1.89543 0.999634 1 1.89506 1 2.99963V12.9996C1 14.1042 1.89543 14.9996 3 14.9996H13C14.1046 14.9996 15 14.1042 15 12.9996V2.99963C15 1.89506 14.1046 0.999634 13 0.999634H3ZM10.7852 4.45671L7.16394 8.07797L5.70686 6.6209L4.29265 8.03511L7.16394 10.9064L12.1994 5.87093L10.7852 4.45671ZM19 16.9996V6.99963C19 5.89506 18.1046 4.99963 17 4.99963V14.9996C17 16.1042 16.1046 16.9996 15 16.9996H5C5 18.1042 5.89543 18.9996 7 18.9996H17C18.1046 18.9996 19 18.1042 19 16.9996ZM23 10.9996V20.9996C23 22.1042 22.1046 22.9996 21 22.9996H11C9.89543 22.9996 9 22.1042 9 20.9996H19C20.1046 20.9996 21 20.1042 21 18.9996V8.99963C22.1046 8.99963 23 9.89506 23 10.9996Z"
            fill="white"/>

    </SvgIcon>
  )
}

export default SelectIcon24
