import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getRegistrars, getUser } from 'modules/user'
import { fetchDomainsStorageList, purchaseDomains } from 'modules/domains'
import DomainsPurchaseForm from "./DomainsPurchaseForm"

const mapStateToProps = (state: RootState) => ({
  registrarsList: getRegistrars(state),
  getUser: getUser(state),
})

const mapDispatchToProps = {
  fetchDomainsStorageList,
  purchaseDomains
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsPurchaseForm)
