export default {
  'NOTIFIER.TITLE.ERROR.USERS_ANONYMOUS_ACCESS_DENIED': 'Анонімний доступ заборонено',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_ACCESS_TOKEN.STRING': 'Поле "access_token" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_CLIENT_ID.STRING': 'Поле "client_id" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_CLIENT_SECRET.STRING': 'Поле "client_secret" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.REQUIRED': 'Поле "email" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.STRING': 'Поле "email" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.EMAIL': 'Невірна адреса електронної пошти',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_NAME.REQUIRED': 'Поле "name" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_NAME.STRING': 'Поле "name" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_PASSWORD.REQUIRED': 'Поле "password" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_PASSWORD.STRING': 'Поле "password" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_STATUS.BOOL': 'Поле "status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.REQUIRED': 'Поле "type" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.STRING': 'Поле "type" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.IN': 'Невідоме значення типу облікових даних користувача',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_USERNAME.STRING': 'Поле "username" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.USERS_CREDENTIAL_FAILED_DELETE': 'Не вдалося видалити облікові дані користувача',
  'NOTIFIER.TITLE.ERROR.USERS_CREDENTIAL_FAILED_SAVE': 'Не вдалося зберегти облікові дані користувача',
  'NOTIFIER.TITLE.ERROR.USERS_INVALID_ARGUMENT_VALUE': 'Невалідне значення аргументу для отримання даних',
  'NOTIFIER.TITLE.ERROR.USERS_NOTFOUND_CREDENTIAL': 'Не вдалося знайти облікові дані користувача',
  'NOTIFIER.TITLE.ERROR.USERS_NOTFOUND_USER': 'Не вдалося знайти профіль користувача',
  'NOTIFIER.TITLE.ERROR.USERS_USER_ACCESS_DENIED': 'Користувачеві заборонено доступ',
  'NOTIFIER.TITLE.ERROR.USERS_USER_BANNED': 'Користувач забанений',
  'NOTIFIER.TITLE.ERROR.USERS_USER_FAILED_CREATE_ACCESS_TOKEN': 'Не вдалося створити "Personal Access Token"',
  'NOTIFIER.TITLE.ERROR.USERS_WRONG_EMAIL_OR_PASSWORD': 'Неправильна електронна адреса або пароль',

  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_PARSER_ID.NUMBER':
    'Поле "credential_parser_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_PARTNER_ID.NUMBER':
    'Поле "credential_partner_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_PROVIDER_ID.NUMBER':
    'Поле "credential_provider_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_TRACKER_ID.NUMBER':
    'Поле "credential_tracker_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_ANCHOR_GENERATION_TYPE.REQUIRED':
    'Поле "anchor_generation_type" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_ANCHOR_GENERATION_TYPE.STRING':
    'Поле "anchor_generation_type" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_ANCHOR_GENERATION_TYPE.IN':
    'Невідоме значення поля "anchor_generation_type"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CAMPAIGN_ID.REQUIRED': 'Поле "campaign_id" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CAMPAIGN_ID.NUMBER': 'Поле "campaign_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATE_START.REQUIRED': 'Поле "date_start" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATE_START.DATE': 'Недійсний формат дати в полі "date_start"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATE_FINAL.REQUIRED': 'Поле "date_final" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATE_FINAL.DATE': 'Недійсний формат дати в полі "date_final".',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATE_FINAL.MINIMUM':
    'Поле "date_final" має бути пізніше або дорівнювати "date_start"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATETIME_START.REQUIRED': 'Поле "datetime_start" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATETIME_START.DATE': 'Невалідний формат дати в полі "datetime_start"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DATETIME_START.MINIMUM': 'Дата початку має бути пізніше поточної',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS.REQUIRED': 'Поле "domains" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS.ARRAY': 'Поле "domains" має бути масивом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS.STRING': 'Поле "domains" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS_ITEM.NUMBER': 'Елементи поля "domains" повинні бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FARMER_ID.REQUIRED': 'Поле "farmer_id" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FARMER_ID.NUMBER': 'Поле "farmer_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID.REQUIRED':
    'Поле "group_id" відсутнє або порожнє, але обов\'язкове без поля "title"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID.ARRAY': 'Поле "group_id" має бути масивом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID.NUMBER': 'Поле "group_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID_ITEM.NUMBER': 'Елементи поля "group_id" повинні бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.REQUIRED':
    'Поле "title" відсутнє або пусте, але є обов\'язковим без поля "group_id"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.STRING': 'Поле "title" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.SIZE_MAXIMUM': 'Вміст поля "title" задовгий',
  'NOTIFIER.TITLE.ERROR.MATERIALS.BADREQUEST_VALIDATION.BOOL': 'Поле "validation" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DESCRIPTION.STRING': 'Поле "description" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DESCRIPTION.SIZE_MAXIMUM': 'Вміст поля "description" задовгий',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_HTTP_CUSTOM_STATUS.NUMBER': 'Поле "http_custom_status" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_HTTP_CUSTOM_STATUS.IN':
    'Невідоме значення спеціального статусу HTTP. Має бути валідний статус HTTP',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.REQUIRED': 'Поле "file" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.FILE': 'Поле "file" має містити валідний файл',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.MIME': 'Неправильний тип файлу в полі "file"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.REQUIRED': 'Поле "link" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.STRING': 'Поле "link" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.SIZE_MAXIMUM': 'Вміст поля "link" задовгий',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINKS_GENERATION_TYPE.REQUIRED':
    'Поле "links_generation_type" відсутнє або пустеа',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINKS_GENERATION_TYPE.STRING':
    'Поле "links_generation_type" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINKS_GENERATION_TYPE.IN':
    'Невідоме значення "links_generation_type" поля',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINKS_LIMIT.NUMBER': 'Поле "links_limit" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINKS_LIMIT.MINIMUM': 'Обмеження кількості посилань має бути більше 0',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_MODE.REQUIRED': 'Поле "mode" відсутнє або пусте.',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_MODE.STRING': 'Поле "mode" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_MODE.IN': 'Невідоме значення режиму створення сніпетів пошуку',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_PAGE.NUMBER': 'Сторінка має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_PER_PAGE.NUMBER': 'Кількість елементів на сторінці має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_REDIRECT_STATUS.BOOL': 'Поле "redirect_status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SERVER_ID.REQUIRED': 'Поле "server_id" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SERVER_ID.NUMBER': 'Поле "server_id" має бути числом',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.REQUIRED': 'Поле "source_id" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.STRING': 'Поле "source_id" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.SIZE_MAXIMUM': 'Вміст поля "source_id" задовгий',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_STATUS.BOOL': 'Поле "status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_STATUS.STRING': 'Поле "status" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_STATUS.IN': 'Невідоме значення статусу домену пісочниці',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_BY.STRING': 'Поле "sort_by" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_BY.IN': 'Невідоме значення поля сортування',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_METHOD.STRING': 'Поле "sort_method" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_METHOD.IN': 'Невідоме значення методу сортування',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_THREADS.REQUIRED': 'Поле "threads" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_THREADS.STRING': 'Поле "threads" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_THREADS.IN': 'Невідоме значення стилю конфігурації потоків',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TEMPLATE.REQUIRED': 'Поле "template" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TEMPLATE.STRING': 'Поле "template" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TEMPLATE.IN': 'Невідоме значення поля "template"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_EMPTY_DOMAINS_LIST': 'Список доменів порожній',
  'NOTIFIER.TITLE.ERROR.MATERIALS_EMPTY_KEYS_LIST': 'Список ключів порожній',
  'NOTIFIER.TITLE.ERROR.MATERIALS_INVALID_ARGUMENT_VALUE': 'Невалідне значення аргументу для отримання даних',
  'NOTIFIER.TITLE.ERROR.MATERIALS_CAMPAIGN_FAILED_SAVE': 'Не вдалося зберегти кампанію',
  'NOTIFIER.TITLE.ERROR.MATERIALS_DOMAIN_FAILED_SAVE': 'Не вдалося зберегти домен',
  'NOTIFIER.TITLE.ERROR.MATERIALS_DOMAIN_PROVIDER_ZONE_INACTIVE': 'У провайдера доменна зона неактивна',
  'NOTIFIER.TITLE.ERROR.MATERIALS_GROUP_FAILED_SAVE': 'Не вдалося зберегти групу ключів',
  'NOTIFIER.TITLE.ERROR.MATERIALS_EXTERNAL_PROCESS_FAILED_SAVE': 'Не вдалося зберегти зовнішній процес',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PARSER_FAILED_SEND_REQUEST': 'Не вдалося надіслати запит до аналізатора',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PARSER_WRONG_FINISH_DATA': 'Неправильні дані для завершення зовнішнього процесу',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PROVIDER_FAILED_SEND_REQUEST': 'Не вдалося надіслати запит провайдеру',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PROVIDER_UNKNOWN_ZONE_PARAMETER':
    'Параметр невідомої зони, який потрібно змінити в провайдері',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PROVIDER_UNKNOWN_ZONE_PARAMETER_VALUE':
    'Невідоме значення параметра зони для зміни в провайдері',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PROVIDER_UNKNOWN_RECORD_TYPE':
    'Невідомий тип запису DNS для створення в постачальнику',
  'NOTIFIER.TITLE.ERROR.MATERIALS_KEY_FILE_FAILED_READ': 'Не вдалося прочитати дані з файлу зі списком ключів',
  'NOTIFIER.TITLE.ERROR.MATERIALS_KEY_FILE_FAILED_UPLOAD': 'Не вдалося завантажити файл зі списком ключів',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_CAMPAIGN': 'Не вдалося знайти кампанію',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_DOMAIN': 'Не вдалося знайти домен',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_FARMER': 'Не вдалося знайти ферму',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_GROUP': 'Не вдалося знайти групу ключів',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_SERVER': 'Не вдалося знайти сервер',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_PARSER': 'Невідомий парсер зовнішнього процесу',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_PARTNER': 'Невідомий партнер',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_PROVIDER': 'Невідомий провайдер для створення доменних зон',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_TRACKER': 'Невідомий трекер',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_EXTERNAL_PROCESS_TYPE': 'Невідомий зовнішній тип процесу',
  'NOTIFIER.TITLE.ERROR.MATERIALS_UNKNOWN_LINK_GENERATION_MODE': 'Невідомий режим створення посилань для домену',

  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.REQUIRED': 'Поле "email" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.STRING': 'Поле "email" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.EMAIL': 'Невірна адреса електронної пошти',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_SUBJECT.STRING': 'Поле "subject" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.REQUIRED': 'Поле "message" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.STRING': 'Поле "message" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.SIZE_MAXIMUM': 'Вміст поля "message" задовгий',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_FEEDBACK_FAILED_SAVE': 'Не вдалося зберегти повідомлення',

  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_COST.REQUIRED': 'Поле "cost" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_COST.NUMBER': 'Поле "cost" має бути числом',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_DOMAIN.REQUIRED': 'Поле "domain" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_DOMAIN.STRING': 'Поле "domain" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_DOMAIN.UNIQUE': 'Цей домен вже використовується',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_FGET_STATUS.REQUIRED': 'Поле "fget_status" відсутнє або пусте',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_FGET_STATUS.BOOL': 'Поле "fget_status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_FILE_STATUS.BOOL': 'Поле "file_status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_IP.STRING': 'Поле "ip" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_PAGE.NUMBER': 'Сторінка має бути числом',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_PER_PAGE.NUMBER': 'Кількість елементів на сторінці має бути числом',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_SORT_BY.STRING': 'Поле "sort_by" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_SORT_BY.IN': 'Невідоме значення поля сортування',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_SORT_METHOD.STRING': 'Поле "sort_method" має бути валідним рядком',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_SORT_METHOD.IN': 'Невідоме значення методу сортування',
  'NOTIFIER.TITLE.ERROR.FARMS_BADREQUEST_STATUS.BOOL': 'Поле "status" має бути "boolean"',
  'NOTIFIER.TITLE.ERROR.FARMS_INVALID_ARGUMENT_VALUE': 'Недійсне значення аргументу для отримання даних',
  'NOTIFIER.TITLE.ERROR.FARMS_DOMAIN_FAILED_LAUNCH_RESPONSE': 'Не вдалося надіслати запит RUN',
  'NOTIFIER.TITLE.ERROR.FARMS_DOMAIN_FAILED_SAVE': 'Не вдалося зберегти домен',
  'NOTIFIER.TITLE.ERROR.FARMS_NOTFOUND_DOMAIN': 'Не вдалося знайти домен',
  'NOTIFIER.TITLE.ERROR.FARMS_SHOP_FAILED_SEND_REQUEST': 'Не вдалося надіслати запит до магазину домена',

  'NOTIFIER.TITLE.ERROR.DEFAULT': 'Вибач, щось пішло не так.<br />Спробуй ще раз 🥺',

  'NOTIFIER.TITLE.SUCCESS.DOMAINS_ADDED': 'Домени успішно<br />додані',
  'NOTIFIER.TITLE.SUCCESS.CREATE_DOMAINS': 'Пачка доменів відправлена для створення',
  'NOTIFIER.TITLE.SUCCESS.DELETE_DOMAINS': 'Домени успішно<br />видалені',
  'NOTIFIER.TITLE.SUCCESS.PURCHASE_DOMAINS': 'Процес придбання доменів розпочато',
  'NOTIFIER.TITLE.SUCCESS.CREATE_CAMPAIGNS': 'Кампанію створено успішно',
  'NOTIFIER.TITLE.SUCCESS.CREATE_SNIPPETS': 'Сніпети згенеровані!',
  'NOTIFIER.TITLE.SUCCESS.CONUCT_US': "Дякуємо! Ми отримали ваше повідомлення і зв'яжемося з вами найближчим часом",
  'NOTIFIER.TITLE.SUCCESS.ADD_SHELL': 'Шел додано успішно',
  'NOTIFIER.TITLE.SUCCESS.DEFAULT': 'Операція виконана успішно',
  'NOTIFIER.TITLE.SUCCESS.PURCHASE': 'Процес по придбанню доменів розпочато, слідкуйте за статусами',

  'NOTIFIER.INFO.TEXT.CREATE_SNIPPETS':
    '{mode, select, all {Для всіх ключів} new {Для нових ключів без сніпетів} other {Для старих ключів зі сніпетами}} групи {groupTitle} у {threads} потоків.',
  'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.UPLOADED': 'Всього ключів',
  'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.IMPORTED': 'Успішні',
  'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.FAILED_DUPLICATED': 'Дублікати',
  'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.FAILED_TEXT': 'Невалідні',

  'NOTIFIER.BUTTON.ACTION.OK': 'ОK',

  'LANDING_PAGE.TITLE.MAKE_MAGIC': 'Творіть магію.<br />Генеруйте клієнтів.',
  'LANDING_PAGE.MENU.ABOUT_US': 'Про нас',
  'LANDING_PAGE.MENU.CONTACT': 'Контакти',
  'LANDING_PAGE.MENU.LOG_IN': 'Увійти',
  'LANDING_PAGE.TITLE.LETS_START': 'Давайте<br />поговоримо<br />про ваші цілі.',
  'LANDING_PAGE.BUTTON.SUBMIT.CONTACT_US': 'Відправити',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.ORGANIC_TRAFFIC': 'Виключно<br />органічний трафік',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.INDUSTRY_FOCUS': 'Орієнтація<br />на галузь<br />та інновації',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.TECH_SOLUTIONS': 'Технологічні<br />рішення',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.ORGANIC_TRAFFIC':
    'Ми спеціалізуємося на органічному трафіку у великих обсягах, забезпечуючи високі показники конверсії, із мотивованими відвідувачами. Попрощайтеся з нерелевантними кліками та відчуйте силу чистого органічного трафіку.',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.INDUSTRY_FOCUS':
    'Працюємо переважно у вертикалях Adult та iGaming, виточуємо майстерність постійно адаптуючись і шукаючи свіжі підходи в цих сферах.',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.TECH_SOLUTIONS':
    'Наша команда має великий досвід роботи у великих компаніях. Ми пропонуємо гнучкі, індивідуальні рішення з використанням новітніх технологій для досягнення ваших унікальних цілей.',

  'SIGN_IN_PAGE.TITLE.LOGIN': 'Вхід',
  'SIGN_IN_PAGE.TITLE.HEADER': 'Чи віриш<br />ти у свою<br />долю?',
  'SIGN_IN_PAGE.TITLE.HEADER.NARROW': 'Чи віриш ти у<br />свою долю?',
  'SIGN_IN_PAGE.BUTTON.LOGIN': 'Увійти',

  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.STATISTICS': 'Статистика',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.MOBILE.STATISTICS': 'Статка',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.KYES': 'Ключі',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.DOMAINS': 'Домени',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.SHELLS': 'Шели',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.CAMPAIGNS': 'Кампанії',
  'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.LOGOUT': 'Вийти',

  'STATISTICS_PAGE.TITLE': 'Статистика',
  'STATISTICS_PAGE.SUBTITLE.INCOME': 'Дохід',
  'STATISTICS_PAGE.TEXT.INCOME.TODAY_AVG': 'Сьогодні / середнє за день',
  'STATISTICS_PAGE.TEXT.INCOME.WEEK': 'Поточний тиждень',
  'STATISTICS_PAGE.TEXT.INCOME.MONTH': 'Поточний місяць',

  'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.SUBTITLE.PARTNERS': 'Партнери',
  'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.DAY': 'День',
  'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.WEEK': 'Тиждень',
  'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.MONTH': 'Місяць',
  'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.PARTNER_NAME.COMMON': 'Загалом',

  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.SUBTITLE.FARMS': 'Ферми',
  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.TITLE': 'Назва',
  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.REQUESTS': 'Запитів за добу',
  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.REQUESTS_HOURLY': 'Сер. запитів за годину (за добу)',
  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.DOMAIN_ACTIVE': 'Активних доменів',
  'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.DOMAIN_AMOUNT': 'Доменів загалом',

  'DOMAINS_PAGE.TITLE': ' Домени',
  'DOMAINS_PAGE.TABS.TITLE.DOMAINS_STORAGE': 'Сховище',
  'DOMAINS_PAGE.TABS.TITLE.DOMAINS_ACTIVE': 'Активні',
  'DOMAINS_STORAGE.SUBTITLE': 'Сховище доменів',
  'DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS': 'Створити пачку',
  'DOMAINS_STORAGE.BUTTON.BUY_DOMAINS': 'Купити домени',
  'DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS.TOOLTIP': 'Спочатку обери хоча б один домен',
  'DOMAINS_STORAGE.DIALOG.TITLE.CREATE_DOMAINS': 'Створення пачки доменів',
  'DOMAINS_STORAGE.DIALOG.TITLE.PURCHASE_DOMAINS': 'Придбати обрані домени',
  'DOMAINS_STORAGE.BUTTON.ADD_DOMAINS': 'Додати домени',
  'DOMAINS_STORAGE.DIALOG.TITLE.ADD_DOMAINS': 'Додати домени',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.CREATED_AT': 'Дата',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.NAIM': 'Назва',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.INDEX': 'Індекс',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.STATUS': 'Статус',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.REGISTRAR': 'Реєстратор',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.STATUS_PROVIDER': 'Статус пров.',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.STATUS_REGISTRAR': 'Статус реєстр.',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.DNS': 'DNS',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.DNS1': 'DNS1',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.DNS2': 'DNS2',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.TEXT.EMPTY': 'Поки що у вас немає доданих доменів',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TITLE.DELETE_DOMAIN': 'Видалити домен?',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TEXT.DELETE_DOMAIN': 'Ви впевнені, що бажаєте видалити домен {domain}?',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TITLE.DELETE_DOMAINS': 'Видалити обрані домени?',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TEXT.DELETE_DOMAINS': 'Ви впевнені, що бажаєте видалити обрані домени?',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.CANCEL': 'Скасувати',
  'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.DELETE': 'Видалити',

  'DOMAINS_ACTIVE.SUBTITLE': 'Активні домени',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.CREATED_AT': 'Дата',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.ID': 'ID',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.HTTP_STATUS': 'Http',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.PROVIDER_ZONE_STATUS.ACTIVE': 'Активний',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.PROVIDER_ZONE_STATUS.PENDING': 'В очікуванні',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DNS': 'DNS',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.NS1': 'NS1',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.NS2': 'NS2',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DOMAIN': 'Назва',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DATETIME_START': 'Ферма',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.REQUESTS_BOTS': 'Боти за добу',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.REQUESTS_KEYS': 'Ключі за добу',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.MARK': 'Мітка',
  'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.TEXT.EMPTY': 'Поки що у вас немає активних доменів',
  'DOMAINS_ACTIVE.BUTTON.CREATE_DOMAINS': 'Створити пачку',

  'DOMAINS_CREATE_PAGE.TITLE': 'Створення пачки доменів',
  'DOMAINS_CREATE_PAGE.BUTTON.SUBMIT.CREATE': 'Створити пачку',
  'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.IFRAME': 'Iframe',
  'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_01': 'Redirect 01',
  'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_02': 'Redirect 02 (HTML5)',
  'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_03': 'Redirect 03',
  'DOMAINS_CREATE_FORM.ALERT.CAMPAIGNS_LIST_EMPTY': 'Немає створених кампаній',
  'DOMAINS_CREATE_FORM.ALERT.KEYS_GROUPS_LIST_EMPTY': 'Немає створених груп ключів',
  'DOMAINS_CREATE_PAGE.BUTTON.CREATE_CAMPAIGN': 'Створити кампанію',
  'DOMAINS_CREATE_PAGE.BUTTON.IMPORT_KEYS': 'Імпортувати ключі',

  'DOMAINS_VALIDATE_PAGE.TITLE': 'Валідація доменів',
  'DOMAINS_VALIDATE_PAGE.BUTTON.SUBMIT.ADD': 'Додати',

  'DOMAINS_PURCHASE_PAGE.BUTTON.SUBMIT.PURCHASE': 'Придбати',

  'KEYS_PAGE.TITLE': 'Ключі',
  'KEYS_PAGE.SUBTITLE': 'Групи ключів',
  'KEYS_PAGE.BUTTON.GENERATE_SNIPPETS': 'Згенерувати сніпети',
  'KEYS_PAGE.BUTTON.IMPORT_KEYS': 'Імпортувати ключі',
  'KEYS_PAGE.BUTTON.ADD_GROUP': 'Додати групу',
  'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.ID': 'ID',
  'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.CREATED_AT': 'Дата',
  'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.TITLE': 'Назва',
  'KEYS_PAGE.KEYS_GROUPS_TABLE.TEXT.EMPTY': 'Поки що у вас немає доданих груп ключів',
  'KEYS_PAGE.DIALOG.TITLE.GENERATE_SNIPPETS': 'Генерування сніпетів',
  'KEYS_PAGE.DIALOG.TITLE.IMPORT': 'Імпорт ключів',

  'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.ALL': 'Для всіх ключів',
  'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.NEW': 'Для нових ключів без сніпетів',
  'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.OLD': 'Для старих ключів зі сніпетами',
  'CREATE_SNIPPETS_FORM.SNIPETS_THREAD_LIST.OPTION.100': '100 потоків',
  'CREATE_SNIPPETS_FORM.SNIPETS_THREAD_LIST.OPTION.200': '200 потоків',
  'CREATE_SNIPPETS_FORM.BUTTON.GENERATE': 'Згенерувати сніпети',

  'IMPORT_KEYS_FORM.BUTTON.SUBMIT.IMPORT': 'Імпортувати ключі',

  'SHELLS_PAGE.TITLE': 'Шели',
  'SHELLS_PAGE.SUBTITLE': 'Список шелів',
  'SHELLS_PAGE.BUTTON.ADD_SHELL': 'Додати шел',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.CREATED_AT': 'Дата',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.ID': 'ID',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.FARM_ID': 'Ферма',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.COST': 'Ціна',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.DOMAIN': 'Домен',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.SYSTEM': 'CMS',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.METHOD': 'Метод',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.FILE_STATUS': 'Статус файлу',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.CACHED_STATUS': 'Кеш',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.ACCESS_STATUS': 'Доступ',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.REQUESTS_TODAY': 'Ботів сьогодні',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.REQUESTS_YESTERDAY': 'Ботів вчора',
  'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.COMMENTS': 'Коментар',
  'SHELLS_PAGE.SHELLS_TABLE.TEXT.EMPTY': 'Поки що у вас немає доданих шелів',
  'SHELLS_PAGE.SHELLS_TABLE.BUTTON.DELETE': 'Видалити',
  'SHELLS_PAGE.SHELLS_TABLE.BUTTON.CHANGE_STATUS': 'Змінити статус',
  'SHELLS_PAGE.DIALOG.TITLE.ADD_SHELL': 'Додавання шела',
  'SHELLS_PAGE.DIALOG.TITLE.DELETE_SHELL': 'Видалити шелл?',
  'SHELLS_PAGE.DIALOG.TEXT.DELETE_SHELL': 'Ви впевнені, що бажаєте видалити шелл {domain}?',
  'SHELLS_PAGE.DIALOG.BUTTON.CANCEL': 'Скасувати',
  'SHELLS_PAGE.DIALOG.BUTTON.DELETE': 'Видалити',
  'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_FGET_STATUS_OPTION.TRUE': 'Вручну',
  'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_FGET_STATUS_OPTION.FALSE': 'Через файл',
  'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_CACHE_STATUS_OPTION.TRUE': 'Так',
  'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_CACHE_STATUS_OPTION.FALSE': 'Ні',

  'CAMPAIGNS_PAGE.TITLE': 'Кампанії',
  'CAMPAIGNS_PAGE.BUTTON.SUBMIT.CREATE': 'Створити кампанію',

  'FORM.FIELD.TITLE.EMAIL': 'Email',
  'FORM.FIELD.TITLE.PASSWORD': 'Пароль',
  'FORM.FIELD.TITLE.MARK': 'Мітка',
  'FORM.FIELD.TITLE.DOMAINS_FILE': 'Текстовий файл з доменами',
  'FORM.FIELD.TITLE.DOMAINS_TYPE': 'Тип домену',
  'FORM.FIELD.LEGEND.DOMAINS_TYPE.DOMAIN_LINK': 'Домен з посиланням',
  'FORM.FIELD.LEGEND.DOMAINS_TYPE.SUBDOMAIN_LINK': 'Саб домен з посиланням',
  'FORM.FIELD.LEGEND.DOMAINS_TYPE.SUBDOMAIN_NO_LINK': 'Саб домен без посилання',
  'FORM.FIELD.TITLE.ANCHOR_TYPE': 'Тип анкору',
  'FORM.FIELD.TITLE.DOMAINS_SERVER': 'Сервер',
  'FORM.FIELD.TITLE.DOMAINS_KEYS_GROUPS': 'Група ключів',
  'FORM.FIELD.TITLE.DOMAINS_SPAM': 'Спам',
  'FORM.FIELD.TITLE.DOMAINS_TIME': 'Час вивантаження (по Києву)',
  'FORM.FIELD.TITLE.DOMAINS_CAMPAIGN': 'Кампанія',
  'FORM.FIELD.TITLE.DOMAINS_LINKS_AMOUNT': 'Посилань на один домен',
  'FORM.FIELD.TITLE.DOMAINS_TEMPLATE': 'Шаблон',
  'FORM.FIELD.TITLE.DOMAINS_FILE_CHECK': 'Файл з доменами на перевірку',
  'FORM.FIELD.TITLE.KEYS_GROUP': 'Група ключів',
  'FORM.FIELD.TITLE.KEYS_GROUP_TITLE': 'Назва групи',
  'FORM.FIELD.TITLE.KEYS_FILE': 'Файл з ключами',
  'FORM.FIELD.TITLE.DOMAINS': 'Список доменів',
  'FORM.FIELD.TITLE.DOMAINS_PURCHASE_TITLE': 'Список реєстраторів',
  'FORM.FIELD.TITLE.ADD_VALIDATION': 'Додати з валідацією',
  'FORM.FIELD.TITLE.PARSER': 'Парсер',
  'FORM.FIELD.TITLE.SNIPPETS_MODE': 'Для яких ключів',
  'FORM.FIELD.TITLE.SNIPPETS_THREADS': 'Кількість потоків',
  'FORM.FIELD.TITLE.CAMPAIGN_TITLE': 'Назва кампанії',
  'FORM.FIELD.TITLE.CAMPAIGN_DESCRIPTION': 'Опис кампанії',
  'FORM.FIELD.TITLE.CAMPAIGN_SOURCE_ID': 'ID джерела',
  'FORM.FIELD.TITLE.CAMPAIGN_LINK': 'Посилання',
  'FORM.FIELD.TITLE.DOMAIN': 'Домен',
  'FORM.FIELD.TITLE.COST': 'Ціна',
  'FORM.FIELD.TITLE.CONTUCT_US_SUBJECT': 'Тема',
  'FORM.FIELD.TITLE.CONTUCT_US_MESSAGE': 'Повідомлення',
  'FORM.FIELD.TITLE.UPLOAD_METHOD': 'Метод завантаження',
  'FORM.FIELD.TITLE.CACHE': 'Кеш',
  'FORM.FIELD.TITLE.FARM': 'Ферма',
  'FORM.FIELD.TITLE.HAND_STATUS': 'Метод завантаження',
  'FORM.FIELD.TITLE.SYSTEM': 'CMS',
  'FORM.FIELD.TITLE.FILENAME': 'Назва файлу',
  'FORM.FIELD.TITLE.COMMENTS': 'Коментар',

  'FORM.FIELD.HELPER_TEXT.TEXTAREA.DOMAINS': 'Один домен - один рядок',
  'FORM.FIELD.HELPER_TEXT.PARSER.THREADS_AND_TASKS': 'Активних потоків: {threads}; aктивних завдань: {tasks}',
  'FORM.FIELD.HELPER_TEXT.MAX_255': 'Максимум 255 символів',

  'FORM.FIELD.BUTTON.DOWNLOAD': 'Завантажити',

  'FORM.FIELD.VALIDATION.ERROR.REQUIRED': "Це поле обов'язкове",
  'FORM.FIELD.VALIDATION.ERROR.EMAIL': 'Недійсна електронна адреса',
  'FORM.FIELD.VALIDATION.ERROR.CHECKBOX.REQUIRED': 'Оберіть мінімум один варіант зі списку',
  'FORM.FIELD.VALIDATION.ERROR.FILE_TYPE': 'Це поле має містити файл одного з типів: text/plain',
  'FORM.FIELD.VALIDATION.ERROR.MAX_255': 'Це поле має містити максимум 255 символів',
  'FORM.FIELD.VALIDATION.ERROR.DATE_MIN': 'Дата і час не можуть бути меншими ніж зараз',
  'FORM.FIELD.VALIDATION.ERROR.LINKS_AMOUNT_MIN': 'Кількість посилань на один домен не може бути меншою ніж 1',
  'FORM.FIELD.VALIDATION.ERROR.COST_MIN': 'Ціна не може бути меншою ніж 1',

  'FORM.TEXT.NO_OPTIONS': 'Немає варіантів',

  'BUTTON.IMPORT_KEYS_FILE': 'Завантажити файл з ключами',
  'BUTTON.GENERATE_SNIPPETS': 'Генерувати пошукові сніпети',
  'BUTTON.COPY_BUTTON': 'Копіювати',
  'BUTTON.COPY_BUTTON.TOOLTIP': 'Дані скопійовано',

  'PAGINATION.TEXT.FROM': 'з {total}',
  'PAGINATION.PER_PAGE.OPTION': '{perPage} на сторінці',

  'SHARED.DATE.TEXT.TODAY': 'Сьогодні',
  'SHARED.DATE.TEXT.TOMORROW': 'Завтра',
  'SHARED.DATE.TEXT.YESTERDAY': 'Вчора',
  'SHARED.DATE.MONTHS_LIST.TEXT.JANUARY': 'Січ.',
  'SHARED.DATE.MONTHS_LIST.TEXT.FEBRUARY': 'Лют.',
  'SHARED.DATE.MONTHS_LIST.TEXT.MARCH': 'Берез.',
  'SHARED.DATE.MONTHS_LIST.TEXT.APRIL': 'Квіт.',
  'SHARED.DATE.MONTHS_LIST.TEXT.MAY': 'Трав.',
  'SHARED.DATE.MONTHS_LIST.TEXT.JUNE': 'Черв.',
  'SHARED.DATE.MONTHS_LIST.TEXT.JULY': 'Лип.',
  'SHARED.DATE.MONTHS_LIST.TEXT.AUGUST': 'Серп.',
  'SHARED.DATE.MONTHS_LIST.TEXT.SEPTEMBER': 'Верес.',
  'SHARED.DATE.MONTHS_LIST.TEXT.OCTOBER': 'Жовт.',
  'SHARED.DATE.MONTHS_LIST.TEXT.NOVEMBER': 'Листоп.',
  'SHARED.DATE.MONTHS_LIST.TEXT.DECEMBER': 'Груд.',
  'SHARED.DATE.DAY_LIST.TEXT.MONDAY': 'Понеділок',
  'SHARED.DATE.DAY_LIST.TEXT.TUESDAY': 'Вівторок',
  'SHARED.DATE.DAY_LIST.TEXT.WEDNESDAY': 'Середа',
  'SHARED.DATE.DAY_LIST.TEXT.THURSDAY': 'Четвер',
  'SHARED.DATE.DAY_LIST.TEXT.FRIDAY': "П'ятниця",
  'SHARED.DATE.DAY_LIST.TEXT.SATURDAY': 'Субота',
  'SHARED.DATE.DAY_LIST.TEXT.SUNDAY': 'Неділя'
}
