import React, { FC, useState, useEffect, ReactNode, MouseEvent, ChangeEvent } from 'react'
import { useLocation, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import TableContainer, { TableContainerProps } from '@mui/material/TableContainer'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableRow from '@mui/material/TableRow'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import TableCell from '@mui/material/TableCell'
import Card from 'components/shared/Card'
import Checkbox from 'components/shared/form/Checkbox'
import SortActiveIcon16 from 'components/shared/icons/16x16/SortActiveIcon16'
import SortDefaultIcon16 from 'components/shared/icons/16x16/SortDefaultIcon16'
import { Pagination, SortMethod } from 'types/shared.types'
import { DEFAULT_PAGINATION_PER_PAGE, PAGINATION_OPTIONS } from 'constants/shared.contants'
import theme from 'configs/theme.config'
import TablePaginationActions from './TablePaginationActions'

interface TableParams<TTableHead, TFetchData> extends TableContainerProps {
  tableHead: TTableHead
  fetchTableData?: TFetchData
  sortByDefault?: string
  isTableData: boolean
  emptyTitle?: string
  emptyContent?: ReactNode
  pagination?: Pagination
  isAllChecked?: boolean
  handleAllChecked?: () => void
  defaultPaginationPerPage?: number
  paginationOptions?: number[]
  onTableChange?: () => void
}

const Table: FC<TableParams> = ({
  sortByDefault = '',
  tableHead,
  fetchTableData,
  isTableData,
  emptyTitle,
  emptyContent,
  pagination,
  isAllChecked,
  handleAllChecked,
  children,
  defaultPaginationPerPage = DEFAULT_PAGINATION_PER_PAGE,
  paginationOptions = PAGINATION_OPTIONS,
  onTableChange,
  ...props
}) => {
  const intl: IntlShape = useIntl()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()
  const isXSSize = useMediaQuery(theme.breakpoints.down('xs'))

  const [isDataFetched, setIsDataFetched] = useState(!fetchTableData)
  const [sortMethod, setSortMethod] = useState((searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC)
  const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || sortByDefault)

  const handleFetchTableData = (
      newSortMethod: SortMethod,
      newSortBy: string,
      page?: number,
      perPage?: number,
      dataMin?: number | null,
      dataMax?: number | null,
      status?: string[] | null
  ) => {
    try {
      const params = {
        sortMethod: newSortMethod,
        sortBy: newSortBy,
        ...(pagination ? { page: String(page), perPage: String(perPage) } : {}),
        ...(dataMin ? {dataMin: dataMin} : {}),
        ...(dataMax ? {dataMax: dataMax} : {}),
        ...(status ? { status: Array.isArray(status) ? status : [status] } : {}),
      }

      fetchTableData(...Object.values(params))
      navigate(
        {
          pathname,
          search: createSearchParams({ ...searchParams, ...params }).toString(),
          hash
        },
        { replace: true }
      )

      setIsDataFetched(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (fetchTableData) {
      const page = Number(searchParams.get('page')) || 1
      const perPage = Number(searchParams.get('perPage')) || defaultPaginationPerPage
      const dataMin = Number(searchParams.get('dataMin')) || 1
      const dataMax= Number(searchParams.get('dataMax')) || 100
      const status = searchParams.getAll('status').length ? searchParams.getAll('status') : ['verified']

      if (pathname === '/domains/storage') {
        handleFetchTableData(sortMethod, sortBy, page, perPage, dataMin, dataMax, status)
      } else {
        handleFetchTableData(sortMethod, sortBy, page, perPage)
      }

    }
  }, [fetchTableData])

  const handleSort = (property: string) => () => {
    const isAsc = sortBy === property && sortMethod === SortMethod.ASC
    const newSortMethod = isAsc ? SortMethod.DESC : SortMethod.ASC
    const dataMin = Number(searchParams.get('dataMin')) || 1
    const dataMax= Number(searchParams.get('dataMax')) || 100
    const status = searchParams.getAll('status').length ? searchParams.getAll('status') : ['verified']

    setSortMethod(newSortMethod)
    setSortBy(property)

    if (pathname === '/domains/storage') {
      handleFetchTableData(sortMethod, sortBy, 1, pagination?.perPage, dataMin, dataMax, status)
    } else {
      handleFetchTableData(sortMethod, sortBy, 1, pagination?.perPage)
    }

    if (onTableChange) {
      onTableChange()
    }
  }

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const dataMin = Number(searchParams.get('dataMin')) || 1
    const dataMax= Number(searchParams.get('dataMax')) || 100
    const status = searchParams.getAll('status').length ? searchParams.getAll('status') : ['verified']

    if (pathname === '/domains/storage') {
      handleFetchTableData(sortMethod, sortBy, newPage + 1, pagination?.perPage, dataMin, dataMax, status)
    } else {
      handleFetchTableData(sortMethod, sortBy, newPage + 1, pagination?.perPage)
    }

    if (onTableChange) {
      onTableChange()
    }
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const dataMin = Number(searchParams.get('dataMin')) || 1
    const dataMax= Number(searchParams.get('dataMax')) || 100
    const status = searchParams.getAll('status').length ? searchParams.getAll('status') : ['verified']


    if (pathname === '/domains/storage') {
      handleFetchTableData(sortMethod, sortBy, 1, parseInt(event.target.value), dataMin, dataMax, status)
    } else {
      handleFetchTableData(sortMethod, sortBy, 1, parseInt(event.target.value))
    }

    if (onTableChange) {
      onTableChange()
    }
  }

  return isTableData ? (
    <>
      <TableContainer {...props}>
        <MuiTable stickyHeader size="small">
          <TableHead>
            <TableRow>
              {handleAllChecked && (
                <TableCell padding="checkbox">
                  <Checkbox color="primary" checked={isAllChecked} onClick={handleAllChecked} />
                </TableCell>
              )}
              {tableHead.map(({ id, label, sortable }) => {
                const isActive = sortBy === id
                const icon = isActive ? SortActiveIcon16 : SortDefaultIcon16
                const labelText = intl.formatMessage({ id: label })

                return (
                  <TableCell key={id} sortDirection={isActive ? sortMethod : false} size="medium">
                    {sortable ? (
                      <TableSortLabel
                        active={isActive}
                        direction={isActive ? sortMethod : SortMethod.ASC}
                        onClick={handleSort(id)}
                        IconComponent={icon}
                      >
                        {labelText}
                      </TableSortLabel>
                    ) : (
                      labelText
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </MuiTable>
      </TableContainer>
      {pagination ? (
        <TablePagination
          sx={{ mt: 3 }}
          classes={{ root: 'table-pagination' }}
          component="div"
          count={pagination.total}
          page={pagination.page - 1}
          rowsPerPage={pagination.perPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          labelDisplayedRows={({ count }) => intl.formatMessage({ id: 'PAGINATION.TEXT.FROM' }, { total: count })}
          rowsPerPageOptions={paginationOptions.map((value: number) => ({
            label: intl.formatMessage({ id: 'PAGINATION.PER_PAGE.OPTION' }, { perPage: value }),
            value
          }))}
          SelectProps={{
            variant: 'outlined',
            size: 'small',
            renderValue: () => {
              const from = pagination.page * pagination.perPage - pagination.perPage
              return `${from + 1} - ${from + pagination.count}`
            },
            MenuProps: {
              slotProps: {
                paper: {
                  classes: {
                    root: 'pagination-select-menu'
                  }
                }
              },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              }
            }
          }}
          ActionsComponent={TablePaginationActions}
        />
      ) : null}
    </>
  ) : isDataFetched && emptyContent ? (
    <Card alignItems="center" justifyContent="center" height={isXSSize ? 332 : 600} width="100%">
      {emptyTitle ? (
        <Typography variant="LRegular" align="center" pb={4} color="textSecondary">
          {intl.formatMessage({ id: emptyTitle })}
        </Typography>
      ) : null}
      {emptyContent}
    </Card>
  ) : null
}

export default Table
