import { SortMethod, Pagination, PaginationPayload } from './shared.types'
import { FarmersItemPayload, ServersItemPayload } from './materials.types'
import { KeysGroupsListPayload } from './keys.types'
import { CampaignsItemPayload } from './campaigns.types'

export enum DomainsActionType {
  VALIDATE_DOMAINS = 'VALIDATE_DOMAINS',
  CREATE_DOMAINS = 'CREATE_DOMAINS',
  FETCH_PARSERS_INFO = 'FETCH_PARSERS_INFO',
  SET_PARSERS_INFO = 'SET_PARSERS_INFO',
  FETCH_DOMAINS_ACTIVE_LIST = 'FETCH_DOMAINS_ACTIVE_LIST',
  SET_DOMAINS_ACTIVE_LIST = 'SET_DOMAINS_ACTIVE_LIST',
  FETCH_DOMAINS_STORAGE_LIST = 'FETCH_DOMAINS_STORAGE_LIST',
  SET_DOMAINS_STORAGE_LIST = 'SET_DOMAINS_STORAGE_LIST',
  DELETE_DOMAINS = 'DELETE_DOMAINS',
  BUY_DOMAINS = 'BUY_DOMAINS'
}

export interface DomainsState {
  parsersInfo: ParsersInfo | null
  domainsActiveList: DomainsActiveList
  domainsActiveListPagination: Pagination
  domainsStorageList: DomainsStorageList
  domainsStorageListPagination: Pagination
}

export enum DomainsTemplate {
  IFRAME = 'iframe',
  REDIRECT_01 = 'redirect_01',
  REDIRECT_02 = 'redirect_02',
  REDIRECT_03 = 'redirect_03'
}

export interface DomainsTemplateItem {
  id: DomainsTemplate
  title: string
}

export interface DomainsCreateValues {
  domains: number[]
  linksGenerationType: string
  anchorGenerationType: string
  groupId: number[]
  farmerId: string
  datetimeStart: string | null
  campaignId: string
  linksLimit: string
  template: DomainsTemplate
}

export type CreateDomains = (data: DomainsCreateValues) => void

export interface ValidateDomainsValues {
  domains: string
  validation: boolean
  bought: boolean
  credentialParserId?: number | null
  credentialRegistrarId?: number | null
}

export type ValidateDomains = (data: ValidateDomainsValues) => void

export type FetchParsersInfo = () => void

export interface ParsersInfo {
  id: number
  name: string
  type: string
  activeThreads: number
  activeProxyCheckerThreads: number
  tasksInQueue: number
  tasksInWork: number
}

export interface SetParsersInfoAction {
  type: DomainsActionType.SET_PARSERS_INFO
  payload: ParsersInfo
}

export enum DomainsStorageTableHeadIds {
  CREATED_AT = 'created_at',
  DOMAIN = 'domain',
  DATA = 'data',
  STATUS = 'status',
  STATUS_REGISTRAR = 'registrar_domain_status',
  STATUS_PROVIDER = 'provider_zone_status',
  DNS1 = 'provider_zone_nameserver_1',
  DNS2 = 'provider_zone_nameserver_2'
}

export interface DomainsStorageTableHeadCell {
  id: DomainsStorageTableHeadIds
  label: string
  sortable?: boolean
}

export type DomainsStorageTableHead = DomainsStorageTableHeadCell[]

export enum DomainsStorageHash {
  CREATE = '#create',
  VALIDATE = '#validate',
  BUY = '#buy',
  PURCHASE = '#purchase',
}

export type FetchDomainsStorageList = (
  sortMethod: SortMethod,
  sortBy: DomainsStorageTableHeadIds,
  page: number,
  perPage: number,
  dataMin?: number | string,
  dataMax?: number | string,
  status?: string[]
) => void

export interface FetchDomainsStorageListPayload extends PaginationPayload {
  items: DomainsStorageListPayload
}

export interface DomainsStorageItemPayload {
  id: number
  user_id: number
  domain: string
  data: number
  created_at: Date
  status: string
  registrar_domain_status: string
  provider_zone_status: string
  provider_zone_nameserver_1: string
  provider_zone_nameserver_2: string
}

export type DomainsStorageListPayload = DomainsStorageItemPayload[]

export interface DomainsStorageItem {
  id: number
  domain: string
  data: number
  createdAt: Date
  status: string
  registrarDomainStatus: string
  providerZoneStatus: string
  providerZoneNameserver1: string
  providerZoneNameserver2: string
}

export type DomainsStorageList = DomainsStorageItem[]

export interface SetDomainsStorageListAction {
  type: DomainsActionType.SET_DOMAINS_STORAGE_LIST
  payload: FetchDomainsStorageListPayload
}

export enum DomainsActiveTableHeadIds {
  CREATED_AT = 'created_at',
  ID = 'id',
  HTTP_STATUS = 'http_status',
  DNS = 'dns',
  DOMAIN = 'domain',
  DATETIME_START = 'datetime_start',
  REQUESTS_BOTS = 'requests_bots',
  REQUESTS_KEYS = 'requests_keys',
  MARK = 'mark'
}

export interface DomainsActiveTableHeadCell {
  id: DomainsActiveTableHeadIds
  label: string
  sortable?: boolean
}

export type DomainsActiveTableHead = DomainsActiveTableHeadCell[]

export enum ProviderZoneStatus {
  PENDING = 'pending',
  ACTIVE = 'active'
}

export type FetchDomainsActiveList = (
  sortMethod: SortMethod,
  sortBy: DomainsActiveTableHeadIds,
  page: number,
  perPage: number
) => void

export interface FetchDomainsActiveListPayload extends PaginationPayload {
  items: DomainsActiveListPayload
}

export interface DomainActivePayload {
  id: number
  user_id: number
  credential_provider_id: number
  server_id: number
  campaign_id: number
  farmer_id: number
  domain: string
  status: boolean
  redirect_status: boolean
  http_custom_status: number
  http_status: number
  nameserver_1: string
  nameserver_2: string
  provider_zone_id: string
  provider_zone_status: ProviderZoneStatus
  mark: string
  template: string
  links_limit: number
  links_generation_type: string
  anchor_generation_type: string
  datetime_start: string
  requests_bots: number
  requests_keys: number
  created_at: Date
}

export interface DomainsActiveItemPayload {
  domain: DomainActivePayload
  server: ServersItemPayload
  campaign: CampaignsItemPayload
  groups: KeysGroupsListPayload
  farmer: FarmersItemPayload
}

export type DomainsActiveListPayload = DomainsActiveItemPayload[]

export interface DomainsActiveItem {
  createdAt: Date
  id: number
  httpStatus: string
  providerZoneStatus: ProviderZoneStatus
  nameserver1: string
  nameserver2: string
  domain: string
  datetimeStart: Date
  farmer: string
  campaign: string
  requestsBots: number
  requestsKeys: number
  mark: string
}

export type DomainsActiveList = DomainsActiveItem[]

export interface SetDomainsActiveListAction {
  type: DomainsActionType.SET_DOMAINS_ACTIVE_LIST
  payload: FetchDomainsActiveListPayload
}

export interface DomainsTypeOption {
  id: string
  title: string
  text: string
}

export interface AnchorsTypeOption {
  id: string
  title: string
}

export type DeleteDomains = (domains: number[]) => void
export interface PurchaseDomainsValues {
  domains: number[]
  credential_registrar_id?: number | null
}

export type PurchaseDomains = (data: PurchaseDomainsValues) => void
