import React, { FC, useEffect, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FieldTextarea from 'components/shared/form/FieldTextarea'
import FieldCheckbox from 'components/shared/form/FieldCheckbox'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import AddIcon24 from 'components/shared/icons/24x24/AddIcon24'
import { Credential } from 'types/user.types'
import { SortMethod } from 'types/shared.types'
import {
  DomainsStorageTableHeadIds,
  FetchDomainsStorageList,
  FetchParsersInfo,
  ParsersInfo,
  ValidateDomains,
  ValidateDomainsValues
} from 'types/domains.types'
import { DEFAULT_PAGINATION_PER_PAGE } from 'constants/shared.contants'
import { PATH_DOMAINS_STORAGE } from 'constants/routes.constants'
import schema from './DomainsValidateForm.schemas'
import {RegistrarItem, RegistrarsList} from "types/materials.types"

interface DomainsValidateFormParams {
  validateDomains: ValidateDomains
  fetchParsersInfo: FetchParsersInfo
  parsersInfo: ParsersInfo | null
  parsers: Credential[]
  fetchDomainsStorageList: FetchDomainsStorageList,
  registrarsList: RegistrarsList
}

const DomainsValidateForm: FC<DomainsValidateFormParams> = ({
  validateDomains,
  fetchParsersInfo,
  parsersInfo,
  parsers,
  fetchDomainsStorageList,
  registrarsList
}) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as DomainsStorageTableHeadIds) || DomainsStorageTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || DEFAULT_PAGINATION_PER_PAGE

  useEffect(() => {
    fetchParsersInfo()

    const timerId = setInterval(() => {
      fetchParsersInfo()
    }, 10000)

    return () => {
      clearInterval(timerId)
    }
  }, [])

  const validateDomainsValues: ValidateDomainsValues = {
    domains: '',
    validation: true,
    bought: false,
    credentialParserId: null,
    credentialRegistrarId: null,
  }

  const [isParsers, setIsParsers] = useState(validateDomainsValues.validation)
  const [isRegistrar, setIsRegistrar] = useState(validateDomainsValues.bought)

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: validateDomainsValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const isAParser =
    parsers.find(parser => String(parser.id) === String(watch('credentialParserId')))?.name === 'Aparser'

  useEffect(() => {
    setValue('credentialParserId', Number(parsers[0].id))
  }, [parsers])

  const handleValidateDomains = async (values: ValidateDomainsValues) => {
    try {
      const { credentialParserId, ...restValues } = values

      await validateDomains({
        ...restValues,
        ...(values.validation ? { credentialParserId } : {})
      })

      handleFetchDomainsStorageList()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchDomainsStorageList = () => {
    fetchDomainsStorageList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_DOMAINS_STORAGE,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  const handleValidationChange = () => {
    const validation = watch('validation')
    setIsParsers(validation)

    if (!validation) {
      setValue('credentialParserId', '')
    } else {
      setIsRegistrar(false)
      setValue('credentialParserId', Number(parsers[0].id))
      setValue('credentialRegistrarId', '')
      setValue('bought', false)
    }
  }

  const handleBoughtChange = () => {
    const bought = watch('bought')
    setIsRegistrar(bought)

    if (!bought) {
      setValue('credentialRegistrarId', '')
    } else {
      setIsParsers(false)
      setValue('credentialRegistrarId', Number(registrarsList[0].id))
      setValue('credentialParserId', '')
      setValue('validation', false)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleValidateDomains)}>
      <FieldTextarea
        sx={{ mb: 2 }}
        rows={14}
        name="domains"
        control={control}
        label="FORM.FIELD.TITLE.DOMAINS"
        helperText="FORM.FIELD.HELPER_TEXT.TEXTAREA.DOMAINS"
      />
      <FieldCheckbox
        sx={{ mb: 2 }}
        name="validation"
        control={control}
        label="FORM.FIELD.TITLE.ADD_VALIDATION"
        afterFieldChange={handleValidationChange}
      />
      {parsers.length ? (
        <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.PARSER"
          name="credentialParserId"
          control={control}
          optionsList={parsers}
          readOnly={!isParsers}
          getValue={value => (value ? parsers.find(parser => String(parser.id) === String(value)) : '')}
          getNewValue={(newValue: Credential | null) => newValue?.id || ''}
          getOptionLabel={option => (option ? option?.name : '')}
          renderOption={(props, { id, name }) => (
            <MenuItem {...props} key={id}>
              {name}
            </MenuItem>
          )}
          helperText={
            isAParser
              ? intl.formatMessage(
                  { id: 'FORM.FIELD.HELPER_TEXT.PARSER.THREADS_AND_TASKS' },
                  { threads: parsersInfo?.activeThreads || 0, tasks: parsersInfo?.tasksInWork || 0 }
                )
              : undefined
          }
        />
      ) : null}


      <FieldCheckbox
          sx={{ mb: 2 }}
          name="bought"
          control={control}
          label="Додати куплені"
          afterFieldChange={handleBoughtChange}
      />
      <FieldAutocomplete
          readOnly={!isRegistrar}
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.DOMAINS_PURCHASE_TITLE"
          name="credentialRegistrarId"
          control={control}
          optionsList={registrarsList}
          getValue={value => (value ? registrarsList.find(option => String(option.id) === String(value)) : '')}
          getNewValue={(newValue: RegistrarItem| null) => newValue?.id || ''}
          getOptionLabel={option => (option ? intl.formatMessage({ id: option?.name }) : '')}
          renderOption={(props, { name }) => (
              <MenuItem {...props} key={name}>
                {name}
              </MenuItem>
          )}
      />

      <Box display="flex" justifyContent="center" mt={4}>
        <Button type="submit" variant="contained" size="large" fullWidth startIcon={<AddIcon24 />}>
          {intl.formatMessage({ id: 'DOMAINS_VALIDATE_PAGE.BUTTON.SUBMIT.ADD' })}
        </Button>
      </Box>
    </form>
  )
}

export default DomainsValidateForm
