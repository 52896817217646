import { SeriesAreaOptions } from 'highcharts'

export enum StatisticsActionType {
  FETCH_STATISTICS = 'FETCH_STATISTICS',
  SET_STATISTICS = 'SET_STATISTICS',
  FETCH_DOMAIN_STATISTICS = 'FETCH_DOMAIN_STATISTICS',
  SET_DOMAIN_STATISTICS = 'SET_DOMAIN_STATISTICS'
}

export interface StatisticsState {
  commonStatistics: CommonStatistics | null
  partnerStatisticsList: PartnersStatisticsList
  farmsStatisticsList: FarmsStatisticsList,
  domainStatistics: []
}

export type FetchStatistics = (dateStart: string, dateFinal: string) => void

export type FetchDomainStatistics = () => void

export interface CommonStatisticsPayload {
  earnings_avg: number
  earnings_day: number
  earnings_week: number
  earnings_month: number
  statistics: {
    [key: string]: number | null
  }
}

export interface StatisticsItemPayload {
  id: number
  title: string
  statistics: {
    [key: string]: number | null
  }
}

export type StatisticsListPayload = StatisticsItemPayload[]

export interface FarmsStatisticsItemPayload {
  id: number
  title: string
  bots: number
  bots_hourly: number
  requests: number
  requests_hourly: number
  domain_active: number
  domain_amount: number
}

export type FarmsStatisticsListPayload = FarmsStatisticsItemPayload[]

export interface StatisticsPayload {
  common: CommonStatisticsPayload
  campaign: StatisticsListPayload
  partner: StatisticsListPayload
  farm: FarmsStatisticsListPayload
}

export interface CommonStatistics {
  earningsAvg: number
  earningsDay: number
  earningsWeek: number
  earningsMonth: number
}

export type PartnersStatisticsList = Partial<SeriesAreaOptions>[]

export interface FarmsStatisticsItem {
  id: number
  title: string
  bots: number
  botsHourly: number
  requests: number
  requestsHourly: number
  domainActive: number
  domainAmount: number
}

export type FarmsStatisticsList = FarmsStatisticsItem[]

export interface SetStatisticsAction {
  type: StatisticsActionType.SET_STATISTICS
  payload: StatisticsPayload
}
export interface SetDomainStatisticsAction {
  type: StatisticsActionType.SET_DOMAIN_STATISTICS
  payload: DomainStatisticsPayload
}

export interface DomainStatisticsPayload {
  zone: DomainStatisticsPayloadZone
}

export interface DomainStatisticsPayloadZone {
  zone: ZoneItemPayloadList
}

export interface ZoneItemPayload {
  zone: {
    [key: string]: number | null
  }
}

export type ZoneItemPayloadList = ZoneItemPayload[]

export enum StatisticsPeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month'
}

export enum FarmsStatisticsTableHeadIds {
  TITLE = 'title',
  REQUESTS = 'requests',
  REQUESTS_HOURLY = 'requests_hourly',
  DOMAIN_ACTIVE = 'domain_active',
  DOMAIN_AMOUNT = 'domain_amount'
}

export interface FarmsStatisticsTableHeadCell {
  id: FarmsStatisticsTableHeadIds
  label: string
}

export type FarmsStatisticsTableHead = FarmsStatisticsTableHeadCell[]
