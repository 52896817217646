export enum MaterialsActionType {
  FETCH_SERVERS_LIST = 'FETCH_SERVERS_LIST',
  SET_SERVERS_LIST = 'SET_SERVERS_LIST',
  FETCH_FARMERS_LIST = 'FETCH_FARMERS_LIST',
  SET_FARMERS_LIST = 'SET_FARMERS_LIST'
}

export interface MaterialsState {
  serversList: ServersList
  farmersList: FarmersList
}

export type FetchServersList = () => void

export interface ServersItemPayload {
  id: number
  user_id: number
  credential_hosting_id: number
  title: string
  description: string
  source_id: string
  ip: string
  created_at: Date
}

export type ServersListPayload = ServersItemPayload[]

export interface ServersItem {
  id: number
  title: string
}

export type ServersList = ServersItem[]

export interface ServersListAction {
  type: MaterialsActionType.SET_SERVERS_LIST
  payload: ServersListPayload
}

export type FetchFarmersList = () => void

export interface FarmersItemPayload {
  id: number
  user_id: number
  title: string
  description: string
  created_at: Date
}

export type FarmersListPayload = FarmersItemPayload[]

export interface FarmersItem {
  id: number
  title: string
}

export type FarmersList = FarmersItem[]

export interface FarmersListAction {
  type: MaterialsActionType.SET_FARMERS_LIST
  payload: FarmersListPayload
}

export interface RegistrarItem {
  id: number
  name: string
}

export type RegistrarsList = RegistrarItem[]
