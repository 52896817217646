import React, {FC, useEffect, useState} from 'react'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TooltipHover from 'components/shared/TooltipHover'
import Dialog from 'components/shared/Dialog'
import SubtitleBox from 'components/shared/SubtitleBox'
import ElixirIcon16 from 'components/shared/icons/16x16/ElixirIcon16'
import AddIcon16 from 'components/shared/icons/16x16/AddIcon16'
import DeleteIcon16 from 'components/shared/icons/16x16/DeleteIcon16'
import Filter from 'components/shared/Filters'
import Filter2 from 'components/shared/Filters2'
import { SortMethod } from 'types/shared.types'
import {
  FetchDomainsStorageList,
  DeleteDomains,
  DomainsStorageHash,
  DomainsStorageTableHeadIds, DomainsStorageList
} from 'types/domains.types'
import { DEFAULT_PAGINATION_PER_PAGE } from 'constants/shared.contants'
import { PATH_DOMAINS_STORAGE } from 'constants/routes.constants'
import DomainsStorageTable from './DomainsStorageTable'
import DomainsCreateForm from './DomainsCreateForm'
import DomainsValidateForm from './DomainsValidateForm'
import DomainsPurchaseForm from './DomainsPurchaseForm'
import {DomainStatisticsPayload} from "../../../../types/statistics.types";

interface DomainsStorageParams {
  deleteDomains: DeleteDomains
  fetchDomainsStorageList: FetchDomainsStorageList,
  domainsList: DomainsStorageList,
  domainStatistics: DomainStatisticsPayload
}

const DomainsStorage: FC<DomainsStorageParams> = ({ deleteDomains, fetchDomainsStorageList, domainsList, domainStatistics }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as DomainsStorageTableHeadIds) || DomainsStorageTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || DEFAULT_PAGINATION_PER_PAGE

  const [domains, setDomains] = useState<number[]>([])
  const [isDeleteDomains, setIsDeleteDomains] = useState(false)
  const [isDomainsStatusValid, setIsDomainsStatusValid] = useState(false);
  const [isDomainsStatusVerified, setIsDomainsStatusVerified] = useState(false);

  const closeDialog = () => {
    navigate({
      pathname: PATH_DOMAINS_STORAGE,
      search: searchParams.toString()
    })
  }
  const handleClearDomains = () => {
    setDomains([])
  }
  const closeDeleteDialog = () => {
    setIsDeleteDomains(false)
  }

  const handleDeleteDomains = async () => {
    try {
      await deleteDomains(domains)

      setDomains([])

      handleFetchDomainsStorageList()
    } catch (error) {
      console.log(error)
    }

    closeDeleteDialog()
  }

  const handleFetchDomainsStorageList = () => {
    fetchDomainsStorageList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_DOMAINS_STORAGE,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  useEffect(() => {
    if (domains.length === 0) {
      // Якщо масив `domains` пустий, автоматично встановлюємо "Невалідний"
      setIsDomainsStatusValid(false)
      return
    }

    const isValid = domains.every((domainId) => {
      const domain = domainsList.find((item) => item.id === domainId)
      return (
          domain &&
          domain.providerZoneStatus === "active" &&
          domain.status === "bought"
      )
    })

    setIsDomainsStatusValid(isValid)
  }, [domains, domainsList])

  useEffect(() => {
    if (domains.length === 0) {
      // Якщо масив `domains` пустий, автоматично встановлюємо "Невалідний"
      setIsDomainsStatusVerified(false)
      return
    }

    const isValid = domains.every((domainId) => {
      const domain = domainsList.find((item) => item.id === domainId)
      return (
          domain &&
          domain.status === "verified"
      )
    })

    setIsDomainsStatusVerified(isValid)
  }, [domains, domainsList])

  return (
    <>
      <SubtitleBox title="DOMAINS_STORAGE.SUBTITLE" >
        <Box mr={1.5} mb={1.5} display="flex" flexWrap="nowrap" alignItems="end">
          <TooltipHover title="DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS.TOOLTIP" isVisible={!domains.length}>
            <Button
                sx={{ mr: 1.5 }}
                size="small"
                variant="contained"
                disabled={!domains.length || !isDomainsStatusVerified}
                onClick={() =>
                    navigate({
                      pathname: PATH_DOMAINS_STORAGE,
                      search: searchParams.toString(),
                      hash: DomainsStorageHash.PURCHASE
                    })
                }
                startIcon={<AddIcon16 />}
            >

              {intl.formatMessage({ id: 'DOMAINS_STORAGE.BUTTON.BUY_DOMAINS' })}
            </Button>
          </TooltipHover>

          <TooltipHover title="DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS.TOOLTIP" isVisible={!domains.length || !isDomainsStatusValid}>
            <Button
                size="small"
                variant="contained"
                disabled={!domains.length || !isDomainsStatusValid}
                onClick={() =>
                    navigate({
                      pathname: PATH_DOMAINS_STORAGE,
                      search: searchParams.toString(),
                      hash: DomainsStorageHash.CREATE
                    })
                }
                startIcon={<ElixirIcon16 />}
            >
              {intl.formatMessage({ id: 'DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS' })}
            </Button>
          </TooltipHover>
        </Box>
        <Box mr={1.5} mb={1.5} display="flex" flexWrap="nowrap" alignItems="end">
          <TooltipHover title="DOMAINS_STORAGE.BUTTON.CREATE_DOMAINS.TOOLTIP" isVisible={!domains.length}>
            <IconButton
                sx={{ mr: 1.5 }}
                size="small"
                color="outlinedSecondary"
                disabled={!domains.length}
                onClick={() => setIsDeleteDomains(true)}
            >
              <DeleteIcon16 />
            </IconButton>
          </TooltipHover>
          <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() =>
                  navigate({
                    pathname: PATH_DOMAINS_STORAGE,
                    search: searchParams.toString(),
                    hash: DomainsStorageHash.VALIDATE
                  })
              }
              startIcon={<AddIcon16 />}
          >
            {intl.formatMessage({ id: 'DOMAINS_STORAGE.BUTTON.ADD_DOMAINS' })}
          </Button>
        </Box>



      </SubtitleBox>

      {/*<Box mr={1.5} mb={1.5} display="flex" flexWrap="nowrap"><Filter onApplyFilters={fetchDomainsStorageList}/></Box>*/}
      <Box mr={1.5} mb={1.5} display="flex" flexWrap="nowrap">
        <Filter2 onClearDomains={handleClearDomains} onApplyFilters={fetchDomainsStorageList} domainStatistics={domainStatistics}/>
      </Box>
      <DomainsStorageTable domains={domains} setDomains={setDomains} />

      <Dialog
        open={hash === DomainsStorageHash.CREATE}
        onClose={closeDialog}
        dialogTitle={intl.formatMessage({ id: 'DOMAINS_STORAGE.DIALOG.TITLE.CREATE_DOMAINS' })}
      >
        <DomainsCreateForm domains={domains} />
      </Dialog>
      <Dialog
        open={hash === DomainsStorageHash.VALIDATE}
        onClose={closeDialog}
        dialogTitle={intl.formatMessage({ id: 'DOMAINS_STORAGE.DIALOG.TITLE.ADD_DOMAINS' })}
      >
        <DomainsValidateForm />
      </Dialog>
      <Dialog
        open={isDeleteDomains}
        onClose={closeDeleteDialog}
        dialogTitle={intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TITLE.DELETE_DOMAINS' })}
        dialogActions={
          <>
            <Button onClick={closeDeleteDialog} variant="outlined" color="secondary" size="large">
              {intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.CANCEL' })}
            </Button>
            <Button onClick={handleDeleteDomains} variant="contained" color="error" size="large">
              {intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.DELETE' })}
            </Button>
          </>
        }
      >
        <Typography variant="LRegular" align="center">
          {intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TEXT.DELETE_DOMAINS' })}
        </Typography>
      </Dialog>
      <Dialog
          open={hash === DomainsStorageHash.PURCHASE}
          onClose={closeDialog}
          dialogTitle={intl.formatMessage({ id: 'DOMAINS_STORAGE.DIALOG.TITLE.PURCHASE_DOMAINS' })}
      >
        <DomainsPurchaseForm domains={domains}/>
      </Dialog>
    </>
  )
}

export default DomainsStorage
