import { RootState } from 'types/system.types'

export const getUser = (state: RootState) => state.user

export const getIsLoaded = (state: RootState) => getUser(state).isLoaded

export const getIsAuthed = (state: RootState) => getUser(state).isAuthed

export const getNickname = (state: RootState) => getUser(state).nickname

export const getCredentials = (state: RootState) => getUser(state).credentials

export const getParsers = (state: RootState) => getCredentials(state).parsers

export const getRegistrars = (state: RootState) => state.user.credentials.registrar || []
